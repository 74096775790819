@import '@ngg/components/dist/style.css';
@import './nshift.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        -webkit-tap-highlight-color: rgba(100, 100, 100, 0.2);
    }

    input:focus,
    textarea:focus {
        outline: none;
    }

    /* Turn off radius on inputs by default, so ios does not round it automatically */
    :where(
            input[type='text'],
            input[type='number'],
            input[type='search'],
            textarea
        ) {
        border-radius: 0;
    }

    /* Remove clear button on search input */
    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }
    input[type='file']::-webkit-file-upload-button {
        -webkit-appearance: none;
        display: none;
    }

    /* font is not properly aligned so we need extra space top to display Å/Ä/Ö */
    input::placeholder {
        overflow: visible;
        line-height: inherit !important;
        color: theme('colors.grey.300');
    }

    /* Remove arrows on number input */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input::-webkit-list-button {
        opacity: 0 !important;
    }
    input[type='number'] {
        -moz-appearance: textfield;
    }

    ::selection {
        background-color: theme('colors.primary.DEFAULT');
    }

    body {
        color: theme('colors.color-base');
        background-color: theme('colors.body');
    }

    i {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    a.underline:hover {
        opacity: 0.7;
    }

    :root {
        --header-height: 5.0625rem;
        --brand-banner-height: 2.75rem;
    }

    @media screen(lg) {
        :root {
            --header-height: 7.5625rem;
        }
    }
}

@layer components {
    .btn-primary {
        @apply h-10 border border-black bg-black px-4 py-3 text-center text-sm font-normal normal-case text-white transition;
        @apply hover:border-black/[.85] hover:bg-black/[.85] hover:text-white;
    }

    .btn-outlined {
        @apply h-10 border border-grey-300 bg-transparent px-4 py-3 text-center text-sm font-normal normal-case text-black transition;
        @apply hover:border-black hover:bg-black hover:text-white;
    }

    .btn-inverted {
        @apply h-10 border border-white bg-white px-4 py-3 text-center text-sm font-normal normal-case text-black transition;
    }

    .with-triangle {
        filter: drop-shadow(0 1px 3px rgb(0 0 0 / 0.1))
            drop-shadow(0 1px 2px rgb(0 0 0 / 0.1));
    }
    .with-triangle > * {
        clip-path: polygon(
            100% 100%,
            100% 12px,
            calc(100% - 12px) 0,
            calc(100% - 24px) 12px,
            0 12px,
            0 100%
        );
    }

    .prose .ngg-accordion {
        border-top: 1px solid theme('colors.grey.150');
        border-bottom: 1px solid theme('colors.grey.150');
    }

    .prose .ngg-accordion + .ngg-accordion {
        border-top: 0;
        margin-top: 0;
    }

    .prose .ngg-image-text-splash + .ngg-image-text-splash {
        margin-top: 0;
    }

    @keyframes banner {
        from {
            transform: translateX(0%);
        }
        to {
            transform: translateX(var(--banner-translate, -33.333%));
        }
    }

    @keyframes cart-animation {
        0%,
        100% {
            transform: scale(1);
        }
        40% {
            transform: scale(1.2);
        }
        75% {
            transform: scale(0.9);
        }
    }
}

@layer utilities {
    .-translate-50 {
        transform: translate(-50%, -50%);
    }
    .offset-50 {
        left: 50%;
        top: 50%;
    }
    .absolute-center {
        @apply -translate-x-1/2;
        @apply -translate-y-1/2;
        @apply top-1/2;
        @apply left-1/2;
        @apply absolute;
    }
    .absolute-top-center {
        @apply -translate-x-1/2;
        @apply top-0;
        @apply left-1/2;
        @apply absolute;
    }
    .absolute-bottom-center {
        @apply -translate-x-1/2;
        @apply bottom-0;
        @apply left-1/2;
        @apply absolute;
    }
    .columns-all {
        column-span: all;
    }

    .flex-center {
        justify-content: center;
        align-items: center;
    }

    .animation-pause {
        animation-play-state: paused;
    }

    .columns-1-2 {
        grid-template-areas: 'first second' 'first third';
    }

    .columns-1-2 > *:first-of-type {
        grid-area: first;
    }

    .text-balance {
        text-wrap: balance;
    }
}

#klarna-placement *::part(osm-container) {
    border: none;
    padding: 0;
}

#klarna-placement #test-badge-element-id {
    margin-left: -60px !important;
}

#klarna-placement *::part(osm-message),
#klarna-placement *::part(osm-cta) {
    font-size: 12px;
    font-family: var(--nordiska-galleriet-sans-font);
    color: #757575;
}

[id^='zoid-imbox-launcher'],
[id^='zoid-imbox-launcher'] .imbox-frame iframe {
    z-index: 10 !important;
}
